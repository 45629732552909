import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { AutoCompleteCombobox } from '../../components/AutoCompleteCombobox'
import { Button } from '../../components/Button'
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'
import { RichTextInput } from '../../components/RichTextInput'
import { Tooltip } from '../../components/Tooltip'
import RepairOrderActionItem from './RepairOrderActionItem'
import ResolveLineItemModal from './ResolveLineItemModal'
import WriteOffLineItemModal from './WriteOffLineItemModal'

// Icons
import { AddIcon } from '../../components/AddIcon'
import { DocumentCheckIcon } from '../../components/DocumentCheckIcon'
import { MinusIcon } from '../../components/MinusIcon'
import { PencilIcon } from '../../components/PencilIcon'
import { TrashIcon } from '../../components/TrashIcon'

// Utils & Services
import {
  createLineItemFile,
  deleteLineItem,
  deleteLineItemFile,
  updateLineItem,
} from '../../services/lineItem.service'
import {
  createLineItemActionNeeded,
  deleteLineItemActionNeeded,
  updateLineItemActionNeeded,
} from '../../services/lineItems.service'
import { stripHtmlTags, toast } from '../../utils/helpers'

// Store
import { UserStoreContext } from '../../stores/UserStore'

const EMPTY_ACTION_ITEM = {
  action: { id: '', name: '' },
  completedAt: null,
  id: 'temp-0',
  markedCompleteBy: null,
}

/**
 *
 * RepairOrderLineItem
 *
 */
const RepairOrderLineItem = ({
  lineItem,
  actionOptions,
  allTechnicians,
  defaultTechnician,
  repairOrderId,
  dealerId,
  isFirstLineItem,
  setDefaultTechnician,
  openEditModal,
  getUpdatedRepairOrder,
  isRepairOrderResolved,
  onTransferrableChange,
  isDealerReview,
}) => {
  // Context
  const { isDealerUser, isReadOnlyWpcUser, user } = useContext(UserStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [filteredTechnicians, setFilteredTechnicians] = useState([])
  const [technicianQuery, setTechnicianQuery] = useState('')
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false)
  const [selectedFileToDelete, setSelectedFileToDelete] = useState({})
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false)
  const [updatedLineItem, setUpdatedLineItem] = useState({
    ...lineItem,
    actionsNeeded:
      lineItem.actionsNeeded && lineItem.actionsNeeded.length > 0
        ? lineItem.actionsNeeded
        : [{ ...EMPTY_ACTION_ITEM, id: `temp-${Date.now()}-${lineItem.id}` }],
  })
  const [isWriteOffModalOpen, setIsWriteOffModalOpen] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const debounceHandleSuccess = useCallback(
    _.debounce(handleSuccess, 1000, { leading: false, trailing: true }),
    [],
  )

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      technician: lineItem.technician || defaultTechnician,
      problem: lineItem.problem || '<p><br></p>',
    },
  })

  useEffect(() => {
    if (technicianQuery.length >= 2) {
      const lowerCaseQuery = technicianQuery.toLowerCase()

      setFilteredTechnicians(
        allTechnicians.filter((person) => person.toLowerCase().includes(lowerCaseQuery)),
      )
    } else {
      setFilteredTechnicians([])
    }
  }, [technicianQuery, allTechnicians])

  const isResolvable = useMemo(() => {
    const hasProblemText =
      updatedLineItem.problem && stripHtmlTags(updatedLineItem.problem).length > 0
    const hasTechnician =
      updatedLineItem.technician && updatedLineItem.technician.trim().length > 0
    const hasActions = updatedLineItem.actionsNeeded && updatedLineItem.actionsNeeded.length > 0
    const allActionsComplete = updatedLineItem.actionsNeeded.every(
      (action) => action.completedAt != null,
    )

    return hasProblemText && hasActions && allActionsComplete && hasTechnician
  }, [updatedLineItem])

  const isRepairOrderTransferrable = useMemo(() => {
    const hasProblemText =
      updatedLineItem.problem && stripHtmlTags(updatedLineItem.problem).length > 0
    const hasTechnician =
      updatedLineItem.technician && updatedLineItem.technician.trim().length > 0
    const hasActions = updatedLineItem.actionsNeeded && updatedLineItem.actionsNeeded.length > 0

    return hasProblemText && hasActions && hasTechnician
  }, [updatedLineItem])

  useEffect(() => {
    onTransferrableChange(isRepairOrderTransferrable)
  }, [isRepairOrderTransferrable])

  const addActionItem = () => {
    const newItem = {
      ...EMPTY_ACTION_ITEM,
      id: `temp-${Date.now()}`,
    }

    setUpdatedLineItem((prevState) => ({
      ...prevState,
      actionsNeeded: [...prevState.actionsNeeded, newItem],
    }))
  }

  const deleteActionItem = (id) => {
    const updatedActionsNeeded = updatedLineItem.actionsNeeded.filter((item) => id !== item.id)

    if (!id.includes('temp')) {
      deleteLineItemActionNeeded(
        dealerId,
        repairOrderId,
        lineItem.id,
        id,
        setLoading,
        handleError,
        (message) => {
          handleSuccess(message)
          setUpdatedLineItem((prevState) => ({
            ...prevState,
            actionsNeeded: updatedActionsNeeded,
          }))
        },
      )
    } else {
      handleSuccess('Action needed deleted.')
      setUpdatedLineItem((prevState) => ({
        ...prevState,
        actionsNeeded: updatedActionsNeeded,
      }))
    }
  }

  const updateActionNeeded = async (actionNeededId, newActionId, callback) => {
    const successHandler = (message, newActionNeededId = actionNeededId) => {
      handleSuccess(message)
      callback(newActionNeededId)
    }

    if (actionNeededId.includes('temp')) {
      createLineItemActionNeeded(
        dealerId,
        repairOrderId,
        updatedLineItem.id,
        {
          action: newActionId,
        },
        setLoading,
        handleError,
        successHandler,
      )
    } else {
      updateLineItemActionNeeded(
        dealerId,
        repairOrderId,
        updatedLineItem.id,
        actionNeededId,
        {
          action: newActionId,
        },
        setLoading,
        handleError,
        successHandler,
      )
    }
  }

  const updateActionItemStatus = (actionNeededId, checkedValue) => {
    const actionIndex = _.findIndex(updatedLineItem.actionsNeeded, (a) => a.id === actionNeededId)

    if (actionIndex !== -1) {
      const completedAt = checkedValue ? dayjs().format() : null

      updateLineItemActionNeeded(
        dealerId,
        repairOrderId,
        updatedLineItem.id,
        actionNeededId,
        {
          completedAt,
        },
        setLoading,
        handleError,
        (message) => {
          handleSuccess(message)
          const updatedItems = [...updatedLineItem.actionsNeeded]
          updatedItems[actionIndex] = { ...updatedItems[actionIndex], completedAt }
          setUpdatedLineItem((prevState) => ({ ...prevState, actionsNeeded: updatedItems }))
        },
      )
    }
  }

  const saveActionNeeded = (id, newActionId, name) => {
    const itemIndex = _.findIndex(updatedLineItem.actionsNeeded, (item) => item.id === id)
    if (itemIndex !== -1) {
      const callback = (actionId) => {
        const updatedItems = [...updatedLineItem.actionsNeeded]
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          id: actionId,
          action: { ...updatedItems[itemIndex].action, id: newActionId, name },
        }
        setUpdatedLineItem((prevState) => ({ ...prevState, actionsNeeded: updatedItems }))
      }

      updateActionNeeded(updatedLineItem.actionsNeeded[itemIndex].id, newActionId, callback)
    }
  }

  /**
   * Handles saving the technician value.
   * @param {string} value
   */
  const updateTechnician = async (value) =>
    updateLineItem(
      dealerId,
      repairOrderId,
      updatedLineItem.id,
      { technician: value },
      () => {},
      handleError,
      () => {
        debounceHandleSuccess('Technician updated.')

        setUpdatedLineItem((prevState) => ({ ...prevState, technician: value }))

        if (isFirstLineItem) {
          setDefaultTechnician(value)
        }
      },
    )

  /**
   * Debounce saving the technician to the BE.
   */
  const handleTechnicianUpdate = useCallback(_.debounce(updateTechnician, 1000), [])

  /**
   * Handles saving the problem value.
   * @param {string} value
   */
  const updateProblem = async (value) => {
    updateLineItem(
      dealerId,
      repairOrderId,
      updatedLineItem.id,
      { problem: value },
      () => {},
      handleError,
      () => {
        handleSuccess('Problem updated.')
        setValue('problem', value, { shouldValidate: true })
        setUpdatedLineItem((prevState) => ({ ...prevState, problem: value }))
      },
    )
  }

  /**
   * Debounce saving the problem to the BE.
   */
  const handleProblemUpdate = useCallback(_.debounce(updateProblem, 1000), [])

  /**
   * Handles downloading the specified `file`.
   * @param {object} e
   * @param {object} file
   */
  const downloadFile = async (e, file) => {
    e.preventDefault()

    try {
      const url = file.file
      const response = await fetch(url)
      const blobUrl = await response.blob()
      const objectUrl = window.URL.createObjectURL(blobUrl)
      const downloadLink = document.createElement('a')
      downloadLink.href = objectUrl
      downloadLink.download = file.displayName
      downloadLink.click()
      downloadLink.remove()
    } catch (err) {
      handleError('Error downloading file.')
    }
  }

  /**
   * Determines whether or not we display the Write Off button,
   * and if so, whether or not it should be disabled.
   */
  const renderWriteOffButton = () => {
    // Do not display write off button when:
    // The user is a dealer user and the repair order is in WPC review
    if (isDealerUser && !isDealerReview) return null
    // The user is a dealer user and the user has a role of `Service`
    if (isDealerUser && user.dealerUserProfile?.role === 'Service') return null

    // Display, but disable the write off button when:
    let disabled = false
    // The line item is not transferrable or the state is not pending
    if (!isRepairOrderTransferrable || updatedLineItem.state !== 'Pending') disabled = true
    // The user is a WPC user and the user has a role of `Read Only`
    if (!isDealerUser && user.wpcUserProfile?.role === 'Read Only') disabled = true
    // The user is a WPC user with the role of `Admin` and the line item is more than $1000
    if (
      !isDealerUser &&
      user.wpcUserProfile?.role === 'Administrator' &&
      updatedLineItem.claimAmount >= 1000
    ) {
      disabled = true
    }

    return (
      <Tooltip
        display={!isRepairOrderTransferrable || updatedLineItem.state !== 'Pending'}
        content={
          <div className="bg-charcoal-950 max-w-[350px] rounded-md p-2">
            <p className="text-sm text-white">
              The Problem field and Tech # field cannot be empty in order to write off a line item.
            </p>
          </div>
        }
        placement="top"
      >
        <Button
          background="bg-green"
          className="w-full sm:w-auto"
          dataTestId={`writeOffLineItem-${updatedLineItem.id}`}
          label="Write Off"
          onClick={() => setIsWriteOffModalOpen(true)}
          size="sm"
          disabled={disabled}
        />
      </Tooltip>
    )
  }

  return (
    <Disclosure as="div" className="mb-8 mt-2" defaultOpen={lineItem.state === 'Pending'}>
      {({ open }) => (
        <div>
          <span className="flex w-full items-center justify-between">
            <span className="flex">
              <DisclosureButton
                className="rounded-lg pr-4 text-xl font-bold leading-none"
                data-testId={`expandLineItem-${updatedLineItem.id}`}
              >
                {open ? (
                  <MinusIcon className="h-4 w-4 stroke-gray-700" />
                ) : (
                  <AddIcon className="h-4 w-4 stroke-gray-700" />
                )}
              </DisclosureButton>
              <div>
                <span className="text-xl font-bold leading-none text-gray-700">
                  Line ID:
                  <span className="ml-1 uppercase">{updatedLineItem.lineName}</span>
                </span>
                {!isDealerUser && !isRepairOrderResolved && (
                  <button
                    aria-label={`Edit Line Item: ${updatedLineItem.lineName}`}
                    data-testid={`editLineItem-${updatedLineItem.id}`}
                    onClick={() => {
                      openEditModal(updatedLineItem)
                    }}
                    className="inline-flex items-center rounded-lg p-2 text-center hover:bg-gray-100 disabled:cursor-not-allowed"
                    type="button"
                    disabled={isReadOnlyWpcUser}
                  >
                    <PencilIcon className="h-4 stroke-white" />
                  </button>
                )}
              </div>
            </span>
            <span>
              {updatedLineItem.state !== 'Pending' && (
                <span className="bg-brownGray-200 text-charcoal-900 mr-2 rounded-lg px-4 py-1 text-sm font-semibold uppercase">
                  {updatedLineItem.state}
                </span>
              )}
              <span
                className={`text-xl font-bold leading-none text-gray-700 ${
                  updatedLineItem.state === 'Pending' ? '' : 'line-through'
                }`}
              >
                {Number(updatedLineItem.claimAmount).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </span>
            </span>
          </span>

          <DisclosurePanel className="bg-brownGray-100 mt-7 rounded-2xl px-7 pb-2 pt-4 text-gray-500 ">
            <p className="pl-4 text-xl font-bold leading-none text-gray-700">Details</p>

            <div className="grid grid-cols-1 gap-14 md:grid-cols-2">
              <div className="flex flex-col space-y-4">
                <div className="p-4">
                  <label
                    htmlFor={`problem-${lineItem.id}`}
                    className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
                  >
                    RO PROBLEM
                  </label>
                  {isDealerUser ? (
                    <div
                      data-testid={`problemText-${lineItem.id}`}
                      className="border-white-light text-charcoal-900 bg-white-light min-h-[130px] w-full cursor-not-allowed rounded-lg p-3"
                    >
                      <p>{updatedLineItem.problem}</p>
                    </div>
                  ) : (
                    <Controller
                      id={`problem-${lineItem.id}`}
                      data-testid={`problem-${lineItem.id}`}
                      name="problem"
                      control={control}
                      render={({ field: { value } }) => (
                        <RichTextInput
                          id="problem"
                          disabled={updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser}
                          onChange={(change) => {
                            handleProblemUpdate(change)
                          }}
                          value={value}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="px-4">
                  {isDealerUser ? (
                    <div>
                      <div className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                        TECH #
                      </div>

                      <div
                        data-testid={`technicianText-${lineItem.id}`}
                        className="bg-white-light relative flex w-full cursor-not-allowed appearance-none rounded-md border-[1px] px-3 py-2.5 pr-4"
                      >
                        <p className="text-charcoal-900">{updatedLineItem.technician}</p>
                      </div>
                    </div>
                  ) : (
                    <AutoCompleteCombobox
                      id={`technician-${lineItem.id}`}
                      dataTestId={`technician-${lineItem.id}`}
                      label="TECH #"
                      value={watch('technician')}
                      disabled={updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser}
                      onComboboxChange={async (value) => {
                        setValue('technician', value, { shouldValidate: true })
                        await handleTechnicianUpdate(value)
                      }}
                      options={filteredTechnicians}
                      error={errors.technician?.message}
                      onComboboxInputChange={async ({ target: { value } }) => {
                        setValue('technician', value, { shouldValidate: true })
                        setTechnicianQuery(value)
                        await handleTechnicianUpdate(value)
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                <div className="pt-4">
                  <div className="flex flex-row items-start gap-2">
                    <div className="w-5/6">
                      <p className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                        Action Needed
                      </p>
                    </div>
                    <div className="flex w-1/6 flex-none items-center justify-end gap-2">
                      <p className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                        Complete
                      </p>
                    </div>
                  </div>
                </div>
                {updatedLineItem.actionsNeeded.map((item) => (
                  <RepairOrderActionItem
                    key={item.id}
                    item={item}
                    onDelete={deleteActionItem}
                    actionOptions={actionOptions}
                    saveActionNeeded={saveActionNeeded}
                    loading={loading}
                    updateActionItemStatus={updateActionItemStatus}
                    isLineItemPending={updatedLineItem.state === 'Pending'}
                    canEdit={!isDealerUser && !isReadOnlyWpcUser}
                  />
                ))}
                {updatedLineItem.actionsNeeded.length === 0 ? (
                  <p className="my-3 text-center">No actions needed.</p>
                ) : null}
                {!isDealerUser && (
                  <div className="flex justify-end">
                    <Button
                      background="bg-background"
                      dataTestId={`addAction-${lineItem.id}`}
                      onClick={addActionItem}
                      outlined
                      label="Add Action"
                      size="sm"
                      disabled={updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser}
                    />
                  </div>
                )}
              </div>
            </div>

            <div aria-hidden="true" className="border-brownGray-200 m-4 border-b-2"></div>

            <div className="grid grid-cols-1 gap-14 px-4 md:grid-cols-2">
              <div>
                <p className="text-charcoal mb-4 pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                  Documentation
                </p>

                <div>
                  {updatedLineItem.files.map((file) => (
                    <div key={file.id} className="mb-2 flex justify-between text-blue-800">
                      <div className="flex items-center overflow-x-hidden">
                        <DocumentCheckIcon className="mr-2 h-5 w-5" />
                        <a
                          className="underline"
                          href={file.file}
                          onClick={(event) => downloadFile(event, file)}
                          target="_blank"
                        >
                          {file.displayName}
                        </a>
                      </div>
                      <button
                        aria-label="Delete"
                        type="button"
                        onClick={() => {
                          setSelectedFileToDelete(file)
                          setIsDeleteFileModalOpen(true)
                        }}
                        className="inline-flex items-center rounded-lg bg-transparent text-center text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-300 disabled:cursor-not-allowed"
                        disabled={updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser}
                      >
                        <TrashIcon className="text-charcoal-800 h-5 w-5" />
                      </button>
                    </div>
                  ))}

                  {updatedLineItem.files.length === 0 ? (
                    <p className="my-3 text-center">No files uploaded.</p>
                  ) : null}

                  <Modal
                    open={isDeleteFileModalOpen}
                    title="Are you sure?"
                    setOpen={setIsDeleteFileModalOpen}
                    content={
                      <div className="my-8 flex h-full items-center justify-center">
                        <p className="text-center">
                          {`Please confirm that you'd like to delete this file?`}
                        </p>
                      </div>
                    }
                    actions={[
                      {
                        type: 'submit',
                        label: 'Delete',
                        onClick: async () => {
                          const fileId = selectedFileToDelete.id
                          const updatedFiles = updatedLineItem.files.filter(
                            (file) => file.id !== fileId,
                          )
                          await deleteLineItemFile(
                            dealerId,
                            repairOrderId,
                            lineItem.id,
                            fileId,
                            setLoading,
                            handleError,
                            (message) => {
                              handleSuccess(message)
                              setUpdatedLineItem((prevState) => ({
                                ...prevState,
                                files: updatedFiles,
                              }))
                              setIsDeleteFileModalOpen(false)
                            },
                          )
                        },
                      },
                      { type: 'cancel', label: 'Cancel' },
                    ]}
                  />
                </div>
              </div>

              <div>
                <div className="bg-white-light rounded-md border-[1px]">
                  <div className="p-4">
                    <div
                      className={`border-[1px] border-dotted border-blue-500 px-2 ${
                        updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser
                          ? 'hover:cursor-not-allowed'
                          : ''
                      }`}
                    >
                      <FileUploader
                        label="Upload Files"
                        autoSave
                        acceptedFileTypes={[
                          'image/*',
                          'application/pdf',
                          'application/vnd.ms-excel',
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          'application/msword',
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        ]}
                        maxFiles={1}
                        id="file"
                        type="manual"
                        className="bg-white-light min-h-130 mb-0"
                        handleUploadToServer={async (file) => {
                          const formData = new FormData()
                          formData.append('file', file)
                          await createLineItemFile(
                            dealerId,
                            repairOrderId,
                            lineItem.id,
                            formData,
                            setLoading,
                            handleError,
                            (message, newLineItemFile) => {
                              handleSuccess(message)
                              setUpdatedLineItem((prevState) => ({
                                ...prevState,
                                files: [...updatedLineItem.files, newLineItemFile],
                              }))
                            },
                          )
                        }}
                        disabled={updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {updatedLineItem.state === 'Resolved - Written Off' && (
              <div className="mt-4 grid grid-cols-1 gap-14 px-4 md:grid-cols-2">
                <div>
                  <p className="text-charcoal mb-2 pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                    Write Off Reason
                  </p>
                  <p className="text-charcoal-900 text-sm font-semibold  leading-[14px]">
                    {`${updatedLineItem.writeOffDepartment} - ${
                      updatedLineItem.writeOffNotes || 'No write off reason provided.'
                    }`}
                  </p>
                </div>
              </div>
            )}

            <div className="flex flex-col justify-end space-y-2 p-4 sm:flex-row sm:space-x-4 sm:space-y-0">
              {updatedLineItem.state === 'Pending' && (
                <>
                  {!isDealerUser && !isDealerReview && (
                    <Tooltip
                      display={!isResolvable && updatedLineItem.state === 'Pending'}
                      content={
                        <div className="bg-charcoal-950 max-w-[350px] rounded-md p-2">
                          <p className="text-sm text-white">
                            Resolve a line item only after completing all actions. Problem field
                            and Tech # field cannot be empty.
                          </p>
                        </div>
                      }
                      placement="top"
                    >
                      <Button
                        background="bg-background"
                        className="w-full sm:w-auto"
                        label="Resolve"
                        dataTestId={`resolveLineItem-${lineItem.id}`}
                        onClick={() => setIsResolveModalOpen(true)}
                        size="sm"
                        disabled={
                          !isResolvable ||
                          (isResolvable && updatedLineItem.state !== 'Pending') ||
                          isReadOnlyWpcUser
                        }
                      />
                    </Tooltip>
                  )}

                  {renderWriteOffButton()}

                  {!isDealerUser && !isDealerReview && (
                    <Button
                      background="bg-red"
                      dataTestId={`deleteLineItem-${lineItem.id}`}
                      className="w-full sm:w-auto"
                      label="Delete Line Item"
                      onClick={async () => {
                        await deleteLineItem(
                          dealerId,
                          repairOrderId,
                          lineItem.id,
                          setLoading,
                          handleError,
                          handleSuccess,
                        )
                        await getUpdatedRepairOrder()
                      }}
                      size="sm"
                      disabled={updatedLineItem.state !== 'Pending' || isReadOnlyWpcUser}
                      showConfirmDialog
                      confirm={{
                        title: `Delete Line Item ${updatedLineItem.lineName}`,
                        type: 'warning',
                        message: `Are you sure you want to delete this line item (Line ID: ${updatedLineItem.lineName})?`,
                      }}
                    />
                  )}
                </>
              )}
              {!isDealerUser && updatedLineItem.state !== 'Pending' && (
                <Button
                  background="bg-background"
                  className="w-full sm:w-auto"
                  dataTestId={`unresolveLineItem-${lineItem.id}`}
                  disabled={isReadOnlyWpcUser}
                  label="Unresolve"
                  onClick={async () => {
                    await updateLineItem(
                      dealerId,
                      repairOrderId,
                      updatedLineItem.id,
                      { markAsUnresolved: true },
                      setLoading,
                      handleError,
                      handleSuccess,
                    )
                    await getUpdatedRepairOrder()
                  }}
                  size="sm"
                  showConfirmDialog
                  confirm={{
                    title: `Unresolve Line Item ${updatedLineItem.lineName}`,
                    type: 'warning',
                    message: `Are you sure you want to unresolve this line item (Line ID: ${updatedLineItem.lineName})?`,
                  }}
                />
              )}

              <ResolveLineItemModal
                isOpen={isResolveModalOpen}
                setIsOpen={setIsResolveModalOpen}
                dealerId={dealerId}
                repairOrderId={repairOrderId}
                setUpdatedLineItem={setUpdatedLineItem}
                lineItem={updatedLineItem}
                getUpdatedRepairOrder={getUpdatedRepairOrder}
              />

              <WriteOffLineItemModal
                isOpen={isWriteOffModalOpen}
                setIsOpen={setIsWriteOffModalOpen}
                dealerId={dealerId}
                repairOrderId={repairOrderId}
                setUpdatedLineItem={setUpdatedLineItem}
                lineItem={updatedLineItem}
                getUpdatedRepairOrder={getUpdatedRepairOrder}
              />
            </div>
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  )
}

export default RepairOrderLineItem

RepairOrderLineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
  actionOptions: PropTypes.array.isRequired,
  allTechnicians: PropTypes.array.isRequired,
  defaultTechnician: PropTypes.string,
  repairOrderId: PropTypes.string.isRequired,
  dealerId: PropTypes.string.isRequired,
  isFirstLineItem: PropTypes.bool.isRequired,
  setDefaultTechnician: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
  isRepairOrderResolved: PropTypes.bool.isRequired,
  onTransferrableChange: PropTypes.func.isRequired,
  isDealerReview: PropTypes.bool.isRequired,
}
