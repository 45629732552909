// Utils and Service
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

/**
 * Gets the Dealer for the specified `id`.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getDealer = async (dealerId, setLoading, setError, setSuccess = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/${dealerId}/?expand=group,manufacturer`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    const error = getErrorMessage(err)

    if (
      error.includes('given query') ||
      error.includes('%(value)s') ||
      error.includes('Not found')
    ) {
      setError('Dealer not found.')
    } else {
      setError(error)
    }
    setLoading(false)
    return null
  }
}

/**
 * Updates the dealer with the specified `payload`.
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const updateDealer = async (payload, setLoading, setError, setSuccess = () => {}) => {
  setLoading(true)

  try {
    const { data } = await axios.patch(`/dealers/${payload.id}/?expand=group`, payload)

    setSuccess(true)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the repair orders using the specified `url`.
 * @param {string} url
 * @param {object} cancelToken
 * @param {func} onProgress
 */
export const getRepairOrders = async (url, setLoading, setError, setSuccess = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the KPIs using the specified `url`.
 * @param {string} url
 * @param {func} setLoading
 * @param {func} setError
 * @returns `data` results
 */
export const getRepairOrderKpis = async (url, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the actions needed for the specified `dealerId`.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @returns `data` results
 */
export const getActionsNeeded = async (dealerId, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/dealers/${dealerId}/repair-orders/actions/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the advisors needed for the specified `dealerId`.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @returns `data` results
 */
export const getAdvisors = async (dealerId, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/dealers/${dealerId}/repair-orders/advisors/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the technicians needed for the specified `dealerId`.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @returns `data` results
 */
export const getTechnicians = async (dealerId, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/dealers/${dealerId}/repair-orders/technicians/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/*
 * Creates a repair order export with the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 */
export const createRepairOrderExport = async (url, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.post(url)

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/*
 * Gets the repair order export with the specified `dealerId` and `exportId`.
 * @param {string} dealerId
 * @param {string} exportId
 * @param {func} setError
 * @param {func} setLoading
 */
export const getRepairOrderExport = async (
  dealerId,
  exportId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/${dealerId}/repair-order-exports/${exportId}`)

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
