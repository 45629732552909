import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import React, { Fragment, forwardRef } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 *
 * Select
 *
 */
const Select = forwardRef(
  (
    {
      className,
      customButton,
      disabled,
      icon,
      error,
      label,
      onChange,
      options,
      placeholder,
      style,
      value,
      inputTextClassName,
      dataTestId,
    },
    ref,
  ) => {
    let inputTextColor = 'text-charcoal-400'
    if (value) inputTextColor = 'text-charcoal-900'

    const buttonStyles = mergeClassNames(
      'border-brownGray focus:border-midnight bg-white-light relative w-full cursor-default rounded-md border px-3 py-1 text-left text-gray-900 shadow-sm',
      className,
    )

    return (
      <div style={style}>
        <Listbox
          disabled={disabled}
          onChange={onChange}
          value={value}
          ref={ref}
          data-testid={dataTestId}
        >
          {({ open }) => (
            <div className="flex w-full flex-col place-items-start">
              {label && (
                <label className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                  {label}
                </label>
              )}

              <div className={customButton ? 'relative' : 'relative w-full'}>
                <ListboxButton
                  className={customButton ? 'relative cursor-pointer' : buttonStyles}
                  disabled={!!customButton || disabled}
                  onClick={(e) => e.stopPropagation()}
                >
                  {customButton || (
                    <div
                      className={mergeClassNames(
                        disabled && 'text-charcoal-400 cursor-not-allowed',
                        'flex items-center justify-between gap-2',
                      )}
                    >
                      {icon && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
                          {icon}
                        </div>
                      )}

                      <span
                        className={mergeClassNames(
                          `block truncate ${inputTextColor} text-xl font-semibold ${inputTextClassName}`,
                          icon && 'pl-5',
                          disabled && 'text-charcoal-400',
                        )}
                      >
                        {value ? value.label : placeholder}
                      </span>

                      <span className="pointer-events-none flex items-center pr-2">
                        <ChevronDownIcon
                          className={`w-8 ${disabled ? 'text-charcoal-400' : 'text-charcoal-900'}`}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  )}
                </ListboxButton>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions
                    className={joinClassNames(
                      customButton ? 'w-40' : 'w-full',
                      'bg-white-light absolute z-20 max-h-60 overflow-auto rounded-md py-1 text-base shadow-lg focus:outline-none sm:text-sm',
                    )}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {options.map((o) => (
                      <ListboxOption
                        key={o.id}
                        className="hover:bg-midnight text-charcoal-900 group relative flex cursor-default select-none gap-2 px-4 py-2 hover:text-white"
                        value={o}
                      >
                        {() => (
                          <div className="flex w-full justify-between gap-4">
                            <span
                              className={joinClassNames(
                                value && o.id === value.id ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              )}
                            >
                              {o.label}
                            </span>

                            {value && o.id === value.id ? (
                              <span className="text-purple flex items-center">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </div>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>

        {error ? (
          <div className="mt-1 w-full bg-transparent px-2 py-1 text-center" aria-hidden="false">
            <p className="text-error min-h-[24px] text-sm font-medium" id={`error:${label}`}>
              {error}
            </p>
          </div>
        ) : null}
      </div>
    )
  },
)

Select.defaultProps = {
  className: '',
  customButton: null,
  dataTestId: null,
  disabled: false,
  error: null,
  icon: null,
  label: null,
  placeholder: 'Select an Option',
  style: {},
  value: null,
}

Select.propTypes = {
  className: PropTypes.string,
  customButton: PropTypes.element,
  inputTextClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dataTestId: PropTypes.string,
}

export default Select
