import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

// Components
import { CheckBox } from '../CheckBox'
import TermsAndConditionsModal from './TermsAndConditionsModal'

const TermsAndConditions = ({ control, errors }) => {
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div>
      <div className="mb-2">
        <Controller
          name="acceptedTermsAndConditions"
          control={control}
          rules={{
            required:
              'You cannot register without reviewing and accepting the Terms and Conditions.',
          }}
          defaultValue=""
          render={({ field: { value, onChange, ...field } }) => (
            <CheckBox
              {...field}
              label={
                <>
                  I have read and agree to the
                  <button
                    type="button"
                    className="ml-1 text-blue-900 underline"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Terms and Conditions
                  </button>
                </>
              }
              disabled={!isCheckboxEnabled}
              onChange={(event) => onChange(event.target.checked)}
              value={value}
            />
          )}
        />
      </div>

      {errors.acceptedTermsAndConditions && (
        <div className="my-1 w-full bg-transparent text-center" aria-hidden="false">
          <p className="text-error min-h-[24px] text-sm font-medium">
            {errors.acceptedTermsAndConditions.message}
          </p>
        </div>
      )}

      <p className="mb-6 text-center text-sm font-normal italic text-gray-500">
        You must open the Terms and Conditions in order to accept.
      </p>
      <TermsAndConditionsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onAccept={() => {
          setIsCheckboxEnabled(true)
          setIsModalOpen(false)
        }}
      />
    </div>
  )
}

export default TermsAndConditions

TermsAndConditions.propTypes = {
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
}
