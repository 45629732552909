import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty'
import {
  $getRoot,
  $insertNodes,
  $setSelection,
  BLUR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { normalizeHtml } from '../../utils/helpers'

// Components
import CustomAutoLinkPlugin from './AutoLinkPlugin'
import Toolbar from './Toolbar'

/**
 * Plugin that monitors changes to the editor state.
 */
const OnChangePlugin = ({ onChange, initialHtml }) => {
  const [editor] = useLexicalComposerContext()

  // State
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (!initialHtml || !firstRender) return

    if (editor) {
      setFirstRender(false)

      // Insert initial HTML
      editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(initialHtml, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)
        $insertNodes(nodes)

        // Clear focus from editor
        $setSelection(null)
      })

      // Register update listener
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const htmlString = $generateHtmlFromNodes(editor, null)
          onChange(htmlString)
        })
      })
    }
  }, [editor])

  useEffect(() => {}, [editor])
}

OnChangePlugin.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialHtml: PropTypes.string.isRequired,
}

/**
 * Plugin that monitors changes to the editor state when focus leaves input.
 */
const EditorBlurPlugin = ({ defaultValue }) => {
  // Context
  const [editor] = useLexicalComposerContext()
  const isTextContentEmpty = useLexicalIsTextContentEmpty(editor)

  // State
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (defaultValue) {
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          if (isTextContentEmpty && !firstRender) {
            editor.update(() => {
              // Remove all current nodes
              const root = $getRoot()
              root.clear()

              // Reset to default value
              const parser = new DOMParser()
              const dom = parser.parseFromString(defaultValue, 'text/html')
              const nodes = $generateNodesFromDOM(editor, dom)
              $insertNodes(nodes)
            })
          }

          setFirstRender(false)
        },
        COMMAND_PRIORITY_EDITOR,
      )
    }
  }, [])

  return null
}

EditorBlurPlugin.propTypes = {
  defaultValue: PropTypes.string.isRequired,
}

/**
 * Plugin that displays a reset button.
 * - Resets the editor state to the default value.
 */
const ResetPlugin = ({ buttonLabel, defaultValue }) => {
  // Context
  const [editor] = useLexicalComposerContext()

  return (
    <button
      className="text-purple mt-2 self-end text-xs"
      onClick={() => {
        if (editor) {
          editor.update(() => {
            // Remove all current nodes
            const root = $getRoot()
            root.clear()

            // Reset to default value
            const parser = new DOMParser()
            const dom = parser.parseFromString(defaultValue, 'text/html')
            const nodes = $generateNodesFromDOM(editor, dom)
            $insertNodes(nodes)
          })
        }
      }}
      type="button"
    >
      {buttonLabel}
    </button>
  )
}

ResetPlugin.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
}

/**
 *
 * RichTextInput
 *
 */
const RichTextInput = ({
  className,
  defaultValue,
  disabled,
  error,
  historyEnabled,
  id,
  inputStyles,
  onChange,
  placeholder,
  resetOnBlur,
  toolbarStyles,
  value,
}) => {
  // Ref to store the last value
  const valueRef = useRef(value)
  const initialLoadRef = useRef(true)

  // Update the ref whenever `value` changes
  useEffect(() => {
    valueRef.current = value
  }, [value])

  // Check for strikethrough in an HTML string
  const containsStrikethrough = (text) =>
    text.includes('<s>') ||
    text.includes('editor-text-strikethrough') ||
    (text.includes('&lt;s&gt;') && text.includes('&lt;/s&gt;'))

  /**
   * Handles changes to the editor state.
   * - Passes HTML string to `onChange`
   */
  const handleChange = (html) => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false
      return
    }
    // Check if the string has a strikethrough
    const htmlContainsStrikethrough = containsStrikethrough(html)
    const valueContainsStrikethrough = containsStrikethrough(valueRef.current)

    const normalizedHtml = normalizeHtml(html)
    const normalizedValue = normalizeHtml(valueRef.current)

    // Only call onChange if the value has changed
    if (
      valueContainsStrikethrough !== htmlContainsStrikethrough ||
      normalizedValue !== normalizedHtml
    ) {
      onChange(html)
    }
  }

  return (
    <LexicalComposer
      initialConfig={{
        editable: !disabled,
        // Default to undefined, we'll handle loading the initial value in the OnChangePlugin
        editorState: undefined,
        theme: {
          link: 'editor-link',
          list: {
            ul: 'editor-list-ul',
            listitem: 'editor-listitem',
            nested: {
              listitem: 'editor-nestedListItem',
            },
            olDepth: ['editor-ol1', 'editor-ol2', 'editor-ol3', 'editor-ol4', 'editor-ol5'],
          },
          text: {
            strikethrough: 'editor-text-strikethrough',
            underline: 'editor-text-underline',
          },
        },
        onError(err) {
          // eslint-disable-next-line no-console
          console.log('Rich Text Error:', err)
        },
        nodes: [AutoLinkNode, ListNode, ListItemNode, LinkNode],
      }}
      key={`rich-text-${id}`}
    >
      <div
        className={mergeClassNames(
          `text-md border-white-light text-charcoal-900 ${
            disabled ? 'text-charcoal-400 cursor-not-allowed' : 'text-charcoal-900'
          } bg-white-light mt-1 h-[150px] min-h-[130px] w-full rounded-md p-1`,
          className,
        )}
      >
        <Toolbar className={toolbarStyles} historyEnabled={historyEnabled} disabled={disabled} />

        <div className={mergeClassNames('relative h-[100px] p-2', inputStyles)} data-testid={id}>
          <RichTextPlugin
            contentEditable={<ContentEditable className="h-full overflow-y-auto outline-none" />}
            placeholder={
              <span className="pointer-events-none absolute top-2 text-gray-500">
                {placeholder}
              </span>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          {historyEnabled && <HistoryPlugin />}
          <ListPlugin />
          <LinkPlugin />
          <TabIndentationPlugin />
          <CustomAutoLinkPlugin />
          <OnChangePlugin onChange={handleChange} initialHtml={value} />
          {resetOnBlur && <EditorBlurPlugin defaultValue={defaultValue} />}
        </div>
      </div>

      {error && (
        <div className="w-full bg-transparent px-2 py-1 text-center" aria-hidden="false">
          <p className="text-error min-h-[24px] text-sm font-medium" id={`error:${id}`}>
            {error}
          </p>
        </div>
      )}

      {defaultValue && <ResetPlugin buttonLabel="Reset to Default" defaultValue={defaultValue} />}
    </LexicalComposer>
  )
}

RichTextInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  historyEnabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputStyles: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resetOnBlur: PropTypes.bool,
  toolbarStyles: PropTypes.string,
  value: PropTypes.string,
}

export default RichTextInput
