import { useCallback, useState } from 'react'

export const useHoverItem = () => {
  const [hoveredItemId, setHoveredItemId] = useState(null)

  const handleMouseEnter = useCallback((id) => {
    setHoveredItemId(id) // Update the hovered item
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHoveredItemId(null) // Reset the hover state
  }, [])

  return { hoveredItemId, handleMouseEnter, handleMouseLeave }
}

export default useHoverItem
