import { Textarea } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Modal } from '../../components/Modal'
import { RadioInput } from '../../components/RadioInput'

// Utils & Services
import { updateLineItem } from '../../services/lineItem.service'
import { toast } from '../../utils/helpers'

const DEPARTMENTS = ['Service', 'Parts', 'Other']

/**
 *
 * WriteOffLineItemModal
 *
 */
const WriteOffLineItemModal = ({
  isOpen,
  setIsOpen,
  dealerId,
  repairOrderId,
  lineItem,
  setUpdatedLineItem,
  getUpdatedRepairOrder,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      department: DEPARTMENTS[0],
      notes: '',
    },
  })

  /**
   * Handles updating the line item.
   * @param {object} data
   */
  const onSubmit = (data) =>
    updateLineItem(
      dealerId,
      repairOrderId,
      lineItem.id,
      {
        markAsWrittenOff: true,
        writeOffDepartment: data.department,
        writeOffNotes: data.notes,
      },
      setLoading,
      handleError,
      () => {
        handleSuccess('Line item written off.')
        setIsOpen(false)
        setUpdatedLineItem((prevState) => ({ ...prevState, state: 'Resolved - Written Off' }))
        getUpdatedRepairOrder()
      },
    )

  return (
    <Modal
      open={isOpen}
      title="Write Off Reason"
      setOpen={setIsOpen}
      loading={loading}
      content={
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-2  flex h-full items-center">
            <div className="col-span-full">
              <Controller
                name="department"
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange, value, ref } }) => (
                  <RadioInput
                    className="mt-4 self-center"
                    horizontal
                    name="department"
                    options={DEPARTMENTS.map((d) => ({ id: d, label: d }))}
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    label="Department"
                  />
                )}
              />
            </div>
          </div>
          <div className="my-4 flex flex-wrap">
            <label
              htmlFor={`notes-${lineItem.id}`}
              className="text-charcoal pb-2 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
            >
              Notes
            </label>
            <Textarea
              {...register('notes', { required: 'This field is required' })}
              id={`notes-${lineItem.id}`}
              data-testid="notes"
              className="border-white-light text-charcoal-900 bg-white-light my-4 min-h-[75px] w-full rounded-lg p-3"
            />

            {errors.notes && (
              <div
                aria-hidden="false"
                className="mt-1 w-full bg-transparent px-2 py-1 text-center"
              >
                <p className="text-error min-h-[24px] text-sm font-medium">
                  {errors.notes.message}
                </p>
              </div>
            )}
          </div>
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Write Off',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel' },
      ]}
    />
  )
}

export default WriteOffLineItemModal

WriteOffLineItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  lineItem: PropTypes.object.isRequired,
  setUpdatedLineItem: PropTypes.func.isRequired,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
}
