import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const RepairOrderNumberCell = ({ row, hoveredRowId, onMouseEnter, onMouseLeave }) => {
  // Check if this row is hovered
  if (hoveredRowId === row.id) {
    return (
      <div className="relative w-full">
        <div className="absolute inset-0 z-10 flex items-center">
          <Link
            onMouseEnter={() => onMouseEnter(row.id)}
            onMouseLeave={onMouseLeave}
            to={`/dealer/${row.dealer}/repair-order/${row.id}`}
            className="flex items-center gap-2 rounded-md bg-[#EEEEEE] text-blue-800"
          >
            <span className="text-md whitespace-nowrap">{row.roNumber}</span>
            <p className="sr-only">Repair Order is {row.isOpen ? 'open' : 'not open'}</p>
            <div
              className={`h-[13px] w-[13px] shrink-0 rounded-full border-[3px] ${
                row.isOpen ? 'border-green-800' : 'border-charcoal-400 bg-charcoal-400'
              }`}
            />
          </Link>
        </div>
      </div>
    )
  }

  return (
    <Link
      onMouseEnter={() => onMouseEnter(row.id)}
      onMouseLeave={onMouseLeave}
      to={`/dealer/${row.dealer}/repair-order/${row.id}`}
      className="hover:text-blue-dark flex w-full flex-row items-center gap-2 text-blue-800"
    >
      <span className="text-md truncate">{row.roNumber}</span>
      <p className="sr-only">Repair Order is {row.isOpen ? 'open' : 'not open'}</p>
      <div
        className={`h-[13px] w-[13px] shrink-0 rounded-full border-[3px] ${
          row.isOpen ? 'border-green-800' : 'border-charcoal-400 bg-charcoal-400'
        }`}
      />
    </Link>
  )
}

export default RepairOrderNumberCell

RepairOrderNumberCell.propTypes = {
  row: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  hoveredRowId: PropTypes.string,
}
