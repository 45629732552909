import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
import PropTypes from 'prop-types'
import React from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

const AutoCompleteCombobox = ({
  id,
  label,
  value,
  onComboboxChange,
  onComboboxInputChange,
  options,
  error,
  disabled,
  autoComplete,
  dataTestId,
  containerClassName,
  inputClassName,
}) => (
  <div className={mergeClassNames('flex w-full flex-col', containerClassName)}>
    <label
      htmlFor={id}
      className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
    >
      {label}
    </label>
    <div className="relative">
      <Combobox value={value} onChange={onComboboxChange}>
        <div className="bg-white-light relative flex w-full appearance-none rounded-md border-[1px] px-3 py-2.5 pr-4">
          <ComboboxInput
            id={id}
            data-testid={dataTestId}
            displayValue={(person) => person}
            required
            className={mergeClassNames(
              'text-charcoal-900 disabled:text-charcoal-400 w-full border-none bg-transparent p-0 focus:outline-none focus:ring-0 disabled:cursor-not-allowed',
              inputClassName,
            )}
            disabled={disabled}
            onChange={onComboboxInputChange}
            autoComplete={autoComplete}
          />
        </div>
        {options.length > 0 && (
          <ComboboxOptions className="text-charcoal-900 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {options.map((person) => (
              <ComboboxOption
                key={person}
                value={person}
                className={({ active }) =>
                  `relative cursor-default py-2 pl-2 pr-4 ${
                    active ? 'bg-midnight text-white' : 'text-gray-900'
                  }`
                }
              >
                {({ selected }) => (
                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                    {person}
                  </span>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </Combobox>

      {error && (
        <div aria-hidden="false" className="mt-1 w-full bg-transparent px-2 py-1 text-center">
          <p className="text-error min-h-[24px] text-sm font-medium">{error}</p>
        </div>
      )}
    </div>
  </div>
)

AutoCompleteCombobox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onComboboxInputChange: PropTypes.func.isRequired,
  onComboboxChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
}

AutoCompleteCombobox.defaultProps = {
  error: '',
  disabled: false,
  autoComplete: 'off',
  containerClassName: '',
  inputClassName: '',
}

export default AutoCompleteCombobox
