// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Creates a repair order using the specified `payload`.
 * @param {string} dealerId
 * @param {string} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const createRepairOrder = async (
  dealerId,
  payload,
  setLoading,
  setError,
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/dealers/${dealerId}/repair-orders/`, payload)

    setLoading(false)
    setSuccess('Repair order created.')
    return response
  } catch (err) {
    if (err.response?.data?.roId) {
      setError({
        message: err.response.data.results[0],
        roId: err.response.data.roId,
      })
    } else {
      setError(getErrorMessage(err))
    }

    setLoading(false)
    return null
  }
}

/**
 * Creates a repair order using the specified `payload`.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getRepairOrder = async (dealerId, repairOrderId, setLoading, setError) => {
  setLoading(true)

  try {
    const response = await axios.get(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/?expand=line_items,line_items.files,line_items.actions_needed,line_items.actions_needed.action`,
    )

    setLoading(false)
    return response
  } catch (err) {
    const error = getErrorMessage(err)

    if (
      error.includes('given query') ||
      error.includes('%(value)s') ||
      error.includes('Not found')
    ) {
      setError('Repair Order not found.')
    } else {
      setError(error)
    }
    setLoading(false)
    return null
  }
}

/**
 * Gets the Repair Orders Advisors for the specified 'dealer_id`.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @returns `response` results
 */
export const getRepairOrderAdvisors = async (
  dealerId,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/${dealerId}/repair-orders/advisors/`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the Repair Orders Technicians for the specified 'dealer_id`.
 * @param {string} dealerId
 * @param {func} setLoading
 * @param {func} setError
 * @returns `response` results
 */
export const getRepairOrderTechnicians = async (
  dealerId,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(`/dealers/${dealerId}/repair-orders/technicians/`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Update repair order.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const updateRepairOrder = async (
  dealerId,
  repairOrderId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/`,
      payload,
    )

    setLoading(false)
    setSuccess('Repair order updated.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the comments for a repair order.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {number} page
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 *
 */
export const getComments = async (
  dealerId,
  repairOrderId,
  cursor = null,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    let url = `/dealers/${dealerId}/repair-orders/${repairOrderId}/comments/?expand=user&limit=10`
    if (cursor) {
      url += `&cursor=${cursor}`
    }

    const response = await axios.get(url)
    setLoading(false)
    setSuccess(response)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a comment using the specified `payload`.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const createComment = async (
  dealerId,
  repairOrderId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(
      `dealers/${dealerId}/repair-orders/${repairOrderId}/comments/?expand=user`,
      payload,
    )

    setLoading(false)
    setSuccess(response)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes a repair order.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {object} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} handleSuccess
 */
export const deleteRepairOrder = async (
  dealerId,
  repairOrderId,
  payload,
  setLoading = () => {},
  setError = () => {},
  handleSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.delete(`/dealers/${dealerId}/repair-orders/${repairOrderId}/`, {
      data: payload,
    })
    setLoading(false)
    handleSuccess()
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
