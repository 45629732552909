import dayjs from 'dayjs'
import { Parser } from 'html-to-react'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Icons
import { AttachmentIcon } from '../../components/AttachmentIcon'
import { CommentsIcon } from '../../components/CommentsIcon'

// Utils & Service
import { Tooltip } from '../../components/Tooltip'
import { ACTION_ICONS } from '../../utils/constants'
import { configureActionItemIcon } from '../../utils/helpers'

export const DEFAULT_FILTERS = {
  actionNeeded: [],
  advisor: [],
  technician: [],
  agesOut: false,
  noUpdate: false,
  highDollar: false,
  highDollarResolved: false,
  new: false,
  resolved: false,
  isOpen: false,
  isClosed: false,
}
export const CHECKBOX_FILTERS = [
  'agesOut',
  'noUpdate',
  'highDollar',
  'new',
  'resolved',
  'isOpen',
  'isClosed',
]

export const CHECKBOX_FILTERS_WITH_DATES = {
  agesOut: `ages_out__lte=${dayjs().add(7, 'day').format('YYYY-MM-DD')}`,
  noUpdate: `last_updated_on__lte=${dayjs().subtract(5, 'day').format('YYYY-MM-DD')}`,
  highDollar: 'total_unresolved_claim_amount__gte=10000',
  highDollarResolved: 'total_claim_amount__gte=10000',
  new: `created_at__gte=${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
  resolved: `resolved_at__gte=${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
  isOpen: 'is_open=true',
  isClosed: 'is_open=false',
}

export const COLUMNS = [
  {
    name: 'RO #',
    selector: (row) => row.roNumber,
    cell: (row) => (
      <Link
        to={`/dealer/${row.dealer}/repair-order/${row.id}`}
        className="hover:text-blue-dark flex w-full flex-row items-center gap-2 text-blue-800"
      >
        <span className="text-md truncate">{row.roNumber}</span>
        <p className="sr-only">Repair Order is {row.isOpen ? 'open' : 'not open'}</p>
        <div
          className={`h-[13px] w-[13px] shrink-0 rounded-full border-[3px] ${
            row.isOpen ? 'border-green-800' : 'border-charcoal-400 bg-charcoal-400'
          }`}
        />
      </Link>
    ),
    sortable: true,
    sortBy: 'ro_number',
    width: '150px',
  },
  {
    name: 'Amount',
    selector: (row) => row.totalUnresolvedClaimAmount,
    format: (row) =>
      `${Number(row.totalUnresolvedClaimAmount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })}`,
    sortable: true,
    sortBy: 'total_unresolved_claim_amount',
    width: '120px',
  },
  {
    name: 'On List',
    selector: (row) => row.daysOnList,
    format: (row) => {
      if (row.daysOnList) {
        return (
          <span>
            {row.daysOnList} day{(row.daysOnList > 1 || row.daysOnList === 0) && 's'}
          </span>
        )
      }
      return <span>N/A</span>
    },
    sortable: true,
    sortBy: 'created_at',
    width: '120px',
  },
  {
    name: 'Ages Out',
    selector: (row) => row.agesOut,
    format: (row) => {
      // Calculate when the repair order will age out (excluding the time)
      const agesOut = dayjs(row.agesOut).startOf('day').diff(dayjs().startOf('day'), 'day')
      return (
        <span className={mergeClassNames(agesOut < 7 && 'text-red')}>
          {agesOut} day{(agesOut > 1 || agesOut === 0) && 's'}
        </span>
      )
    },
    sortable: true,
    sortBy: 'ages_out',
    width: '120px',
  },
  {
    name: 'Action Needed',
    cell: (row) => {
      // Action name and completed status are nested pretty far deep under `lineItems`
      // We need to go through every lineitem's actions needed to get the action name and completed status
      const actions = _.map(
        _.flatMap(row.lineItems, (l) => _.map(l.actionsNeeded, (a) => a)),
        (a) => ({ name: a.action.name, completed: a.completedAt }),
      )

      // Get the unique action icons and completed status
      const actionIcons = _.orderBy(
        _.uniqBy(
          _.map(actions, (a) => ({
            icon: ACTION_ICONS[a.name],
            completed: a.completed,
            name: a.name,
          })),
          'icon',
        ),
        'completed',
        'desc',
      )

      return (
        <div className="flex flex-row items-center gap-1">
          {_.map(actionIcons, (a) => (
            <Tooltip
              placement="top"
              key={a.name}
              content={
                <div className="text-md text-white-dark rounded-lg border border-blue-800 bg-blue-800 p-1.5 shadow-md">
                  <span>{a.name}</span>
                </div>
              }
            >
              {configureActionItemIcon(a.icon, a.completed)}
              <span className="sr-only">{a.name}</span>
            </Tooltip>
          ))}
        </div>
      )
    },
    width: '250px',
  },
  {
    name: 'Problem',
    selector: (row) => row.problem,
    cell: (row) => (
      <div className="h-5 overflow-y-hidden">
        {_.map(row.lineItems, (l) => Parser().parse(l.problem))}
      </div>
    ),
    width: '300px',
  },
  {
    name: 'Last Update',
    selector: (row) => row.lastUpdatedOn,
    format: (row) => {
      const lastUpdate = dayjs(row.lastUpdatedOn)
      const hoursDiff = dayjs().diff(lastUpdate, 'hour')
      return (
        <span className={mergeClassNames(hoursDiff > 48 && 'text-red')}>
          {lastUpdate.format('MM/DD/YYYY')}
        </span>
      )
    },
    sortable: true,
    sortBy: 'last_updated_on',
    width: '150px',
  },
  {
    name: '',
    selector: (row) => row.id,
    cell: (row) => (
      <div className="flex flex-row gap-3">
        <button type="button">
          <AttachmentIcon
            className={mergeClassNames(
              'h-6',
              row.hasUnreadAttachments ? 'stroke-blue-800' : 'stroke-charcoal-light',
            )}
            unread={row.hasUnreadAttachments}
          />
        </button>

        <button type="button">
          <CommentsIcon
            className={mergeClassNames(
              'h-6',
              row.hasUnreadComments ? 'fill-blue-800' : 'fill-charcoal-light',
            )}
            unread={row.hasUnreadComments}
          />
        </button>
      </div>
    ),
    width: '90px',
  },
]

export const RESOLVED_COLUMNS = [
  {
    name: 'RO #',
    selector: (row) => row.roNumber,
    cell: (row) => (
      <Link
        to={`/dealer/${row.dealer}/repair-order/${row.id}`}
        className="hover:text-blue-dark flex flex-row items-center gap-2 text-blue-800"
      >
        <span className="text-md">{row.roNumber}</span>
        <p className="sr-only">Repair Order is {row.isOpen ? 'open' : 'not open'}</p>
        {row.isOpen ? (
          <div className="h-[13px] w-[13px] rounded-full border-[3px] border-green-800" />
        ) : (
          <div className="border-charcoal-400 bg-charcoal-400 h-[13px] w-[13px] rounded-full border-[3px]" />
        )}
      </Link>
    ),
    sortable: true,
    sortBy: 'ro_number',
    width: '150px',
  },
  {
    name: 'Amount',
    selector: (row) => row.totalClaimAmount,
    format: (row) =>
      `${Number(row.totalClaimAmount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })}`,
    sortable: true,
    sortBy: 'total_claim_amount',
    width: '120px',
  },
  {
    name: '# Lines Resolved',
    selector: (row) => row.numLineItemsResolved,
    sortable: true,
    sortBy: 'num_line_items_resolved',
    width: '150px',
  },
  {
    name: '# Written Off',
    selector: (row) => row.numLineItemsWrittenOff,
    sortable: true,
    sortBy: 'num_line_items_written_off',
    width: '150px',
  },
  {
    name: 'ADV ID',
    selector: (row) => row.advisor,
    sortable: true,
    sortBy: 'advisor',
    width: '180px',
  },
  {
    name: 'Tech',
    cell: (row) => (
      <div className="flex flex-row items-center gap-1">
        {_.uniq(_.map(row.lineItems, (l) => l.technician)).join(', ')}
      </div>
    ),
    width: '180px',
  },
  {
    name: 'Days On List',
    selector: (row) => row.daysOnList,
    format: (row) => {
      if (row.daysOnList) {
        return (
          <span>
            {row.daysOnList} day{(row.daysOnList > 1 || row.daysOnList === 0) && 's'}
          </span>
        )
      }
      return <span>N/A</span>
    },
    sortable: true,
    sortBy: 'days_on_list',
    width: '120px',
  },
  {
    name: 'Resolved Date',
    selector: (row) => row.resolvedAt,
    format: (row) => {
      if (row.resolvedAt) {
        return <span>{dayjs(row.resolvedAt).format('MM/DD/YYYY')}</span>
      }
      // Return default message if does not have resolved date
      return 'N/A'
    },
    sortable: true,
    sortBy: 'resolved_at',
    width: '150px',
  },
]
