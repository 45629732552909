import { StrikethroughIcon } from '@heroicons/react/24/solid'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

const BUTTON_STYLES =
  'flex h-7 w-7 cursor-pointer rounded-md border border-transparent align-middle hover:bg-midnight hover:fill-white disabled:cursor-not-allowed'

/**
 * Toolbar
 * - Displays a toolbar for the RichTextInput
 * - Handles all text transformations
 */
const Toolbar = ({ className, disabled, historyEnabled }) => {
  // Context
  const [editor] = useLexicalComposerContext()

  // State
  const [strikethrough, setStrikethrough] = useState(false)

  // Ref
  const toolbarRef = useRef(null)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      setStrikethrough(selection.hasFormat('strikethrough'))
    }
  }, [editor])

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar()
            return false
          },
          1,
        ),
      ),
    [editor, updateToolbar],
  )

  return (
    <div
      className={mergeClassNames(
        'border-charcoal-100 flex flex-row space-x-2 border-b-[1px] p-1',
        className,
      )}
      ref={toolbarRef}
    >
      {/* General */}

      {historyEnabled && (
        <>
          <button
            onClick={() => {
              editor.dispatchCommand(UNDO_COMMAND)
            }}
            className={BUTTON_STYLES}
            aria-label="Undo"
            title="Undo"
            type="button"
            disabled={disabled}
          >
            <box-icon type="regular" color="black" name="undo" size="sm" />
          </button>

          <button
            onClick={() => {
              editor.dispatchCommand(REDO_COMMAND)
            }}
            className={BUTTON_STYLES}
            aria-label="Redo"
            title="Redo"
            type="button"
            disabled={disabled}
          >
            <box-icon type="regular" color="black" name="redo" size="sm" />
          </button>
        </>
      )}

      {/* Strikethrough */}
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        className={mergeClassNames(
          BUTTON_STYLES,
          strikethrough && 'bg-midnight hover:bg-midnight-200 fill-white',
        )}
        aria-label="Format Strikethrough"
        type="button"
        disabled={disabled}
      >
        <StrikethroughIcon className="h-6 fill-inherit" />
      </button>
    </div>
  )
}

Toolbar.defaultProps = {
  className: null,
  historyEnabled: false,
  disabled: false,
}

Toolbar.propTypes = {
  className: PropTypes.string,
  historyEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Toolbar
