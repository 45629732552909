import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import { Notifications } from '../Notifications'
import { UserIcon } from '../UserIcon'

// Images
import authBackground from '../../assets/images/auth_image.svg'
import logoWhite from '../../assets/images/logo_white.svg'
import wordmark from '../../assets/images/wordmark.svg'

// Service
import { logout } from '../../services/user.service'

// Stores
import { NotificationStoreContext } from '../../stores/NotificationStore'
import { RootStoreContext } from '../../stores/RootStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils
import { joinClassNames, toast } from '../../utils/helpers'
import { convertIncomingData } from '../../services/transforms'

/**
 *
 * NavBar
 *
 */
const NavBar = observer(({ options }) => {
  // Context
  const navigate = useNavigate()
  const location = useLocation()
  const { clearStore } = useContext(RootStoreContext)
  const {
    setUnreadNotificationsCount,
    unreadNotificationsCount,
    setTriggerAlert,
    isMenuOpen,
    setHasNewNotifications,
  } = useContext(NotificationStoreContext)
  const { accessToken, isAuthenticated, getUpdatedUser, refreshToken, shouldReceiveNotifications, user } =
    useContext(UserStoreContext)
  const [socket, setSocket] = useState(null)

  const isMenuOpenRef = useRef(isMenuOpen)

  useEffect(() => {
    getUpdatedUser()
  }, [])

  useEffect(() => {
    isMenuOpenRef.current = isMenuOpen
  }, [isMenuOpen])

  /**
   * Set up the WebSocket connection to listen for notifications
   */
  useEffect(() => {
    // If the user is authenticated, should receive in-app notifications,
    // and there and no existing socket, create a new WebSocket connection
    if (isAuthenticated && user?.id && shouldReceiveNotifications && !socket) {
      console.info('Establishing WebSocket connection...')
      const apiURL = new URL(import.meta.env.VITE_API_BASE_URL)
      const protocol = apiURL.protocol === 'https:' ? 'wss' : 'ws'
      const ws = new WebSocket(
        `${protocol}://${apiURL.host}/ws/v1/notifications/${user.id}/?token=${accessToken}`,
      )
      setSocket(ws)

      ws.onmessage = (e) => {
        const msg = convertIncomingData(JSON.parse(e.data))
        // Update the notification count. Notifications may be triggered by a variety of events.
        // We want to trigger an alert/animation of the notification count if the count increases
        // and the trigger is a new notification. Manually marking a message as unread will not
        // trigger the alert/animation.
        if (msg.type === 'unread_notifications_count') {
          if (
            msg.trigger === 'New Notification' && // Only trigger alert for new notifications
            unreadNotificationsCount !== null && // Handle initial load
            msg.count > unreadNotificationsCount // Only trigger alert if the count increases
          ) {
            setTriggerAlert(true)
          }
          setUnreadNotificationsCount(msg.count)
        } else if (msg.type === 'new_notification' && !isMenuOpenRef.current) {
          toast(
            {
              linkLabel: msg.data.description,
              url: `/dealer/${msg.data.data.dealerId}/repair-order/${msg.data.data.roId}`,
            },
            'info',
          )
        } else if (msg.type === 'new_notification') {
          setHasNewNotifications(true)
        }
      }

      ws.onclose = () => {
        console.warn('WebSocket connection closed. Attempting to reconnect...')
        setSocket(null)
      }
    }
  }, [isAuthenticated, user, accessToken, socket])

  // If the user is not authenticated, render a basic nav bar
  if (!isAuthenticated) {
    return (
      <div className="bg-background relative mb-10 flex h-[150px] w-screen place-content-center items-center md:mb-0 md:h-[200px] lg:h-[290px] xl:h-[290px]">
        <img
          aria-hidden="true"
          className="absolute inset-x-0 top-0 z-0 w-full sm:-top-10 lg:-top-2 xl:-top-20"
          src={authBackground}
          alt="Background"
        />

        <img
          aria-hidden="true"
          className="z-10 -mt-14 h-8 sm:mt-0 sm:h-10 md:h-12 lg:h-16"
          src={wordmark}
          alt="Logo"
          onClick={() => navigate('/')}
        />
      </div>
    )
  }

  return (
    <div className="w-screen">
      <Disclosure as="nav" className="bg-midnight">
        {({ open }) => (
          <>
            <div className="px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 justify-between">
                {/* Mobile menu */}
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-100 focus:outline-none ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="stroke-blue-dark block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="stroke-blue-dark block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>

                {/* Navigation Options */}
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  {/* Logo */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    className="flex shrink-0 cursor-pointer items-center"
                    onClick={() => navigate('/')}
                  >
                    <img className="h-6 w-auto lg:block" src={logoWhite} alt="Logo" />
                  </div>
                  {/* Options */}
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {_.map(options, (option) => (
                      <button
                        type="button"
                        key={option.label}
                        className={joinClassNames(
                          location.pathname === option.path
                            ? 'text-gold-300 border-gold-300 border-b-2 '
                            : 'hover:border-gold-300 hover:text-gold-300 border-b-2 border-transparent text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium',
                        )}
                        onClick={() => {
                          if (option.onClick) option.onClick()
                          else navigate(option.path)
                        }}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Profile dropdown */}
                <div className="absolute inset-y-0 right-0 flex items-center gap-2 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {shouldReceiveNotifications && <Notifications />}

                  <Menu as="div" className="relative">
                    <div>
                      <MenuButton
                        className="flex rounded-full bg-transparent text-sm focus:outline-none"
                        data-testid="user-dropdown"
                      >
                        <span className="sr-only">Open user menu</span>
                        <UserIcon className="block h-8 w-8 fill-white" />
                      </MenuButton>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                        {_.map(
                          [
                            { label: 'Profile', path: '/profile' },
                            { label: 'Settings', path: '/settings' },
                            {
                              label: 'Sign Out',
                              onClick: () => logout(refreshToken, clearStore),
                            },
                          ],
                          (option) => (
                            <MenuItem key={option.label}>
                              {({ active: a }) => (
                                <button
                                  type="button"
                                  className={joinClassNames(
                                    a ? 'bg-gray-100' : '',
                                    'block w-full px-4 py-2 text-start text-sm text-gray-700',
                                  )}
                                  onClick={() => {
                                    if (option.onClick) option.onClick()
                                    else navigate(option.path)
                                  }}
                                >
                                  {option.label}
                                </button>
                              )}
                            </MenuItem>
                          ),
                        )}
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            {/* Mobile menu */}
            <DisclosurePanel className="sm:hidden">
              <div className="space-y-1 pb-4 pt-2">
                {_.map(options, (option) => (
                  <DisclosureButton
                    as="a"
                    href={option.path}
                    className={joinClassNames(
                      'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                      location.pathname === option.path
                        ? 'text-gold-300 border-gold-300'
                        : 'hover:border-gold-300 hover:text-gold-300 border-transparent text-white',
                    )}
                    key={option.label}
                  >
                    {option.label}
                  </DisclosureButton>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  )
})

NavBar.propTypes = {
  menuOptions: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  orientation: PropTypes.string,
}

NavBar.defaultProps = {
  menuOptions: null,
  options: null,
  orientation: 'horizontal',
}

export default NavBar
