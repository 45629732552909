import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const DealerNameCell = ({ row, hoveredRowId, onMouseEnter, onMouseLeave }) => {
  // Check if this row is hovered
  if (hoveredRowId === row.id) {
    return (
      <div className="relative w-full">
        <div className="absolute inset-0 z-10 flex items-center">
          <Link
            onMouseEnter={() => onMouseEnter(row.id)}
            onMouseLeave={onMouseLeave}
            to={`/dealer/${row.id}/detail`}
            className="whitespace-nowrap rounded-md bg-[#EEEEEE] px-1 text-sm font-medium leading-5 tracking-[0.25px] text-blue-800"
          >
            {row.name}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <Link
      onMouseEnter={() => onMouseEnter(row.id)}
      onMouseLeave={onMouseLeave}
      className="hover:text-blue-dark truncate px-1 text-sm font-medium leading-5 tracking-[0.25px] text-blue-800"
      to={`/dealer/${row.id}/detail`}
    >
      {row.name}
    </Link>
  )
}

export default DealerNameCell

DealerNameCell.propTypes = {
  row: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  hoveredRowId: PropTypes.string,
}
